import { SignIn } from '@clerk/nextjs'
import { Button, buttonVariants, cn } from '@thenarrative/ui'
import Link from 'next/link'
import { ReactNode } from 'react'
import { SignedOutLayout } from '../../components/layout/SignedOutLayout'
import { ApplicationEnvironment } from '../../utils/ApplicationEnvironment'

const SignInPage = () => (
  <div className='flex h-full w-full items-center justify-center flex-col'>
    <SignIn
      path='/sign-in'
      routing='path'
      signUpUrl='/sign-up'
      appearance={{
        elements: {
          card: 'flex h-full w-full items-center justify-center flex-col shadow-none',
          formButton: 'text-center bg-primary text-primary',
          socialButtonsBlockButton: cn(buttonVariants({ variant: 'secondary', className: 'border-none' })),
          footer: 'hidden',
          // header: 'gap-2',
          headerTitle: 'text-center text-3xl',
          headerSubtitle: 'text-center text-base',
          formHeaderTitle: 'text-center text-base',
          form: 'items-center justify-center text-center',
          // otpCodeFieldInputs: 'text-center text-base text-muted-foreground',
          formResendCodeLink: 'text-center',
        },
      }}
    />
    <p className='absolute bottom-4 left-1/2 -translate-x-1/2 text-muted-foreground text-sm text-center px-4 w-full max-w-[440px]'>
      By using Narrative&apos;s platform, you hereby agree to our <br></br>
      <span className='text-foreground'>
        <Link target='_blank' href={ApplicationEnvironment.TERMS_AND_CONDITIONS}>
          Terms & Conditions
        </Link>
      </span>
      and
      <span className='text-foreground'>
        <Link target='_blank' href={ApplicationEnvironment.PRIVACY_POLICY}>
          Privacy Policy.
        </Link>
      </span>
    </p>
  </div>
)

SignInPage.getLayout = (page: ReactNode) => (
  <SignedOutLayout
    rightHeaderElement={
      <Button variant='outline'>
        <Link href='/sign-up'>Sign Up</Link>
      </Button>
    }
  >
    {page}
  </SignedOutLayout>
)

export default SignInPage
