import { Flexbox, Icon } from '@thenarrative/ui'
import Link from 'next/link'
import { FC, PropsWithChildren } from 'react'

type Props = {
  rightHeaderElement?: React.ReactNode
}

export const SignedOutLayout: FC<PropsWithChildren<Props>> = ({ rightHeaderElement, children }) => (
  <Flexbox height='full' column>
    <Flexbox as='header' justify='between' align='center' className='h-header absolute top-0 left-0 right-0 p-4 z-10'>
      <Link href='/'>
        <Icon width={32} name='NarrativeLogo' />
      </Link>
      {rightHeaderElement}
    </Flexbox>
    {children}
  </Flexbox>
)
